export default {
  methods: {
    isEmpty (value) {
      if (typeof value !== 'undefined' && value !== null && value.length > 0) return false
      return true
    },
    minLength (value, min = 0) {
      return value.length >= min ? false : true
    },
    maxLength (value, max = 255) {
      return value.length <= max ? true : false
    },
    isEmail (value) {
      let regex = /^\S+@\S+\.\S+$/
      return regex.test(value)
    },
    isPhone (value) {

    },
    isUrl (value) {
      let regex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
      return regex.test(value)
    },
    securePassword (password) {
      let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d].{4,}$/
      return regex.test(password)
    }
  }
}