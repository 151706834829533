<template lang="pug">
.signup-links
  LPopup(
  )
    h3.mb-2 {{ $t('signup') }}
    h4 {{ $t('where you want post materials') }}?
    .signup-links__container.mt-2
      img(src="@/assets/public/social/facebook.png")
      img(src="@/assets/public/social/vk.png")
      img(src="@/assets/public/social/inst.png")
      img.mt-1(src="@/assets/public/social/ok.png")

    LButton.mt-2(@click="$router.push('/')") {{ $t('cancel') }}
    LButton.mt-1( theme="blue") {{ $t('next') }}

</template>

<script>
// TODO separate mixins
// fix body scroll
import { mapActions } from 'vuex'

export default {
  name: 'SignupLinks',
  components: {
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton')
  },
  data () {
    return {
      closePopup: false
    }
  }
}
</script>

<style lang="scss">
.signup-links {
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-copntent: center;
    grid-auto-rows: 50px;
    align-items: center;
    & img {
      display: block;
      width: 52px;
      height: 52px;
      align-self: center;
      justify-self: center;
    }
  }
}

</style>