<template lang="pug">
form.signup-additional
  LPopup()
    h3.mt-3.mb-5 {{ $t('send a request') }}
    template(v-if="userRole === 'user'")
      LInput(
        :placeholder="`* ${$t('name and lastname')}`"
        v-model="username"
        v-focus
        :error="usernameErrorsPull.length > 0"
        @blur="validateUsername"
        @focus="clearErrors('username')"
        autocomplite="off"
      )
      p.signup-additional__input-error(v-if="usernameErrorsPull.length > 0") {{ getErrorMessage(usernameErrorsPull) }}
      LInput.mt-1(
        :placeholder="`* ${$t('phone')}`"
        v-model="phone"
        :error="phoneErrorsPull.length > 0"
        @blur="validatePhone"
        @focus="clearErrors('phone')"
        autocomplite="off"
      )
      p.signup-additional__input-error(v-if="phoneErrorsPull.length > 0") {{ getErrorMessage(phoneErrorsPull) }}

    template(v-if="userRole === 'advertiser'")
      .signup-additional__container
        .signup-additional__input
          LInput.mt-1(
            :placeholder="`* ${$t('name lastname')}`"
            v-model="username"
            v-focus
            :error="usernameErrorsPull.length > 0"
            @blur="validateUsername"
            @focus="clearErrors('username')"
            autocomplite="off"
          )
          p.signup-additional__input-error(v-if="usernameErrorsPull.length > 0") {{ getErrorMessage(usernameErrorsPull) }}

        .signup-additional__input
          LInput.mt-1(
            :placeholder="`* ${$t('site url')}`"
            v-model="site"

            :error="siteErrorsPull.length > 0"
            @blur="validateSiteUrl"
            @focus="clearErrors('site')"
            autocomplite="off"
          )
          p.signup-additional__input-error(v-if="siteErrorsPull.length > 0") {{ getErrorMessage(siteErrorsPull) }}

        .signup-additional__input
          LInput.mt-1(
            :placeholder="`* ${$t('type of activity')}`"
            v-model="metier"

            :error="metierErrorsPull.length > 0"
            @blur="validateMetier"
            @focus="clearErrors('metier')"
            autocomplite="off"
          )
          p.signup-additional__input-error(v-if="metierErrorsPull.length > 0") {{ getErrorMessage(metierErrorsPull) }}

        .signup-additional__input
          LInput.mt-1(
            :placeholder="`* ${$t('position')}`"
            v-model="position"

            :error="positionErrorsPull.length > 0"
            @blur="validatePosition"
            @focus="clearErrors('position')"
            autocomplite="off"
          )
          p.signup-additional__input-error(v-if="positionErrorsPull.length > 0") {{ getErrorMessage(positionErrorsPull) }}

        .signup-additional__input
          LAutocomplite.mt-1(
            :placeholder="`* ${$t('territory of activity')}`"
            :preloader="territoryOfActivityPreloader"
            v-model="country"
            :error="countryErrorsPull.length > 0"
            @focus="clearErrors('country')"
            @input="inputCountry"
            ref="countryInput"
          )
            template(slot="items")
              .signup-additional__country-item(
                v-if="countriesList.length > 0"
                v-for="country in countriesList"
                @click="setCountry(country)"
              ) {{ country.name }}

          p.signup-additional__input-error(v-if="countryErrorsPull.length > 0") {{ getErrorMessage(countryErrorsPull) }}
          p.signup-additional__input-label * {{ $t('contry or internet') }}

        .signup-additional__input
          LInput.mt-1(
            :placeholder="`* ${$t('phone')}`"
            v-model="phone"
            autocomplite="off"
            :error="phoneErrorsPull.length > 0"
            @blur="validatePhone"
            @focus="clearErrors('phone')"
          )
          p.signup-additional__input-error(v-if="phoneErrorsPull.length > 0") {{ getErrorMessage(phoneErrorsPull) }}

    LInputFile.signup-additional__avatar.mt-2(
      @change="startCrop"
      v-if="userPhotoUrl === null"
    )
      .signup-additional__avatar-icon
        LIcon(name="photo" color="#fff" v-if="!uploadPreloader")
        LIcon(name="preloader" color="#E12323" v-if="uploadPreloader")

      span.signup-additional__avatar-label.ml-1 {{ $t('upload photo') }}
    p.signup-additional__input-error.mt-1(v-if="photoErrorsPull.length > 0") {{ getErrorMessage(photoErrorsPull) }}

    .signup-additional__user-photo.mt-2(v-if="userPhotoUrl !== null ")
      LAvatar(:url="userPhotoUrl")

    LButton.signup-additional__button.mt-2(@click="send" :preloader="preloader") {{ $t('next') }}

    //- crop popup
    LPopup(
      @open=""
      v-show="cropPopup"
    )
        .signup-additional__crop
          VueCroppie(
            ref="croppieRef"
            :enableOrientation="true"
            :boundary="{ width: 256, height: 256 }"
            :enableResize="false"
            :viewport="{ width: 256, height: 256, type: 'circle' }"
          )
          LButton.mt-2(@click="crop") {{ $t('Отправить') }}

</template>

<script>
// fix body scroll
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import validationMixin from '@/mixins/validation.js'

export default {
  name: 'SignupAdditional',
  components: {
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LInput: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInput'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LInputFile: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInputFile'),
    LAutocomplite: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAutocomplite'),
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar'),
  },

  created () {
    this.userRole = Cookies.get('usertype') || 'advertiser'
  },
  mixins: [validationMixin],
  data () {
    return {
      site: null,
      metier: null,
      phone: null,
      username: null,
      country: null,
      countrySlug: null,
      position: null,
      territoryOfActivity: null,
      countriesList: [],

      userName: null,
      userPhone: null,

      userRole: 'advertiser',
      preloader: false,

      territoryOfActivityPreloader: false,

      uploadPreloader: false,

      usernameErrorsPull: [],
      siteErrorsPull: [],
      metierErrorsPull: [],
      countryErrorsPull: [],
      positionErrorsPull: [],
      phoneErrorsPull: [],
      photoErrorsPull: [],

      cropPopup: false,
      croppedImage: null,
      userPhotoUrl: null,
      noCountryRequest: false
    }
  },
  computed: {
    ...mapGetters('user', ['HAS_AUTH', 'USER_DATA'])
  },
  methods: {
    ...mapActions('user', ['updateSettings', 'uploadPhoto', 'setUserPhoto']),
    ...mapActions('dictonary', ['countries']),
    // CROPPING
    startCrop (ipt) {
      this.cropPopup = true
      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };

    reader.readAsDataURL(ipt.files[0]);
    },
    crop () {
      let options = {
        type: 'blob',
        size: { width: 256, height: 256 },
        format: 'png'
      };
      this.$refs.croppieRef.result(options, output => {
        this.croppedImage = output;
        this.cropPopup = false;
        this.upload(output)
      })
    },
    // VALIDATION TODO REFACTORING
    clearErrors (field) {
      if (field === 'username') this.usernameErrorsPull = []
      if (field === 'site') this.siteErrorsPull = []
      if (field === 'metier') this.metierErrorsPull = []
      if (field === 'country') this.countryErrorsPull = []
      if (field === 'position') this.positionErrorsPull = []
      if (field === 'phone') this.phoneErrorsPull = []
      if (field === 'photo') this.photoErrorsPull = []
    },
    closePopup () {
      this.$router.push('/')
    },
    validateUsername () {
      let valid = true
      // required
      if (this.isEmpty(this.username)) {
        this.usernameErrorsPull.push({ error: 'required', message: 'заполните поле name lastname'})
        valid = false
      } else {
        let errorIndex = this.usernameErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.usernameErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateSiteUrl () {
      let valid = true
      // required
      if (this.isEmpty(this.site)) {
        this.siteErrorsPull.push({ error: 'required', message: 'заполните поле site'})
        valid = false
      } else {
        let errorIndex = this.siteErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.siteErrorsPull.splice(errorIndex, 1)
      }
      // correct url
      if (!this.isUrl(this.site)) {
        this.siteErrorsPull.push({ error: 'valid', message: 'введите корректный url'})
        valid = false
      } else {
        let errorIndex = this.siteErrorsPull.findIndex((element) => element.error === "valid")
        if (errorIndex >= 0) this.siteErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateMetier () {
      let valid = true
      // required
      if (this.isEmpty(this.metier)) {
        this.metierErrorsPull.push({ error: 'required', message: 'заполните поле type of activity'})
        valid = false
      } else {
        let errorIndex = this.metierErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.metierErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateCountry () {
      let valid = true
      // required
      if (this.isEmpty(this.countrySlug)) {
        this.countryErrorsPull.push({ error: 'required', message: 'выбирите страну из списка'})
        valid = false
      } else {
        let errorIndex = this.countryErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.countryErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validatePosition () {
      let valid = true
      // required
      if (this.isEmpty(this.position)) {
        this.positionErrorsPull.push({ error: 'required', message: 'заполните поле должность'})
        valid = false
      } else {
        let errorIndex = this.positionErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.positionErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validatePhone () {
      let valid = true
      // required
      if (this.isEmpty(this.phone)) {
        this.phoneErrorsPull.push({ error: 'required', message: 'заполните поле телефон'})
        valid = false
      } else {
        let errorIndex = this.phoneErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.phoneErrorsPull.splice(errorIndex, 1)
      }


      if (this.minLength(this.phone, 10)) {
        this.phoneErrorsPull.push({ error: 'minlength', message: 'минимум 10 символов'})
        valid = false
      } else {
        let errorIndex = this.phoneErrorsPull.findIndex((element) => element.error === "minlength")
        if (errorIndex >= 0) this.phoneErrorsPull.splice(errorIndex, 1)
      }

      return valid
    },
    getErrorMessage (pull) { // TODO REFACTORING
      if (typeof pull[0] !== 'undefined') {
        return pull[0].message
      }
      return false
    },
    validatePhoto () {
      let valid = true
      if (this.userPhotoUrl === null) {
        this.photoErrorsPull.push({ error: 'required', message: 'загрузите фото'})
        valid = false
      } else {
        let errorIndex = this.photoErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.photoErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateAll () {
      if (this.validateUsername() &&
          this.validateSiteUrl() &&
          this.validateMetier() &&
          this.validateCountry() &&
          this.validatePosition() &&
          this.validatePhone() &&
          this.validatePhoto()
        ) return true
      return false
    },
    validateUserAll () {
      if (this.validateUsername() &&
        this.validatePhone() &&
        this.validatePhoto()
      ) return true
      return false
    },
    send () {
      if (this.userRole === 'user') this.sendUserSettings()
      else this.sendSettings()
    },
    sendSettings () {
      if (!this.validateAll()) return
      this.preloader = true
      this.updateSettings({
        name: this.username,
        type_of_activity: this.metier,
        site: this.site,
        position: this.position,
        phone: this.phone,
        country: this.countrySlug
      })
      .then((response) => {
        this.preloader = false
        if (response.data.result.status) {
          window.location.href = '/app'
        }
      })
    },
    sendUserSettings () {
      if (!this.validateUserAll()) return
      this.preloader = true
      this.updateSettings({
        name: this.username,
        phone: this.phone
      }).then((response) => {
        this.preloader = false
        if (response.data.result.status) {
          this.$router.push('/')
        }
      })
    },
    setCountry (country) {
      this.country = country.name
      this.countrySlug = country.slug
      this.noCountryRequest = true
      this.clearErrors('country')
      this.countriesList = []
    },
    getCountryList (query) {
      if (this.noCountryRequest) {
        this.noCountryRequest = false
        return
      }
      this.territoryOfActivityPreloader = true
      this.countriesList = []
      this.countrySlug = null
      this.countries(query)
      .then((response) => {
        this.countriesList = response.data.data
      })
      .finally(() => {
        this.territoryOfActivityPreloader = false
      })
    },
    upload (blobData) {
      this.uploadPreloader = true
      let data = new FormData()
      data.append('file', blobData);
      this.uploadPhoto(data)
      .then((response) => {
        this.userPhotoUrl = response.data.data.preview
        this.uploadPreloader = false
        this.clearErrors('photo')
        this.setUserPhoto(this.userPhotoUrl)
      })
    },
    inputCountry (event) {
      if (event !== null & event.length >= 3) {
        this.getCountryList(event)
      }
    }
  },
  watch: {
    country (newVal) {
      if (newVal !== null & newVal.length >= 3) {
        this.getCountryList(newVal)
      }
    }
  }
}
</script>

<style lang="scss">
.signup-additional {
  &__country-item {
    text-align: left;
    padding: 10px 10px;
    &:hover {
      background: #c4c4c4;
      cursor: pointer;
    }
  }
  &__crop {
    display: block;
    & > .croppie-container {
      max-width: 100%;
    }
  }
  & .lpopup {
    &__body {
      display: flex;
      flex-direction: column;
      max-width: 720px;
    }
  }
  &__input {
    max-width: 290px;
    &-error {
      color: #E12323;
      margin-top: 2px;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
    }
    &-label {
      color: #0A156A;
      text-transform: uppercase;
      text-align: left;
      font-size: 14px;
      font-weight: 700;
      margin-top: 10px;
    }
  }
  &__avatar {
    align-self: flex-start;
    display: flex !important;
    align-items: center;
    width: 100%;
    &-label {
      text-transform: uppercase;
      font-weight: 700;
    }
    &-icon {
      background: #c4c4c4;
      border-radius: 10px;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      & .licon {
        width: 38px;
        height: 38px;
      }
    }
  }
  &__button {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
  }
  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: space-between;
    justify-content: space-between;
    width: 100%;
    @media(min-width: 768px) {
      flex-direction: row;
    }
  }
}
</style>