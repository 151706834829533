<template lang="pug">
.terms-of-use
  LPopup(
  )
    h3.mt-3.mb-5 {{ $t('terms of use') }}
    iframe.terms-of-use__view(:src="url")
    LButton(theme="blue") {{ $t('back') }}
    LButton.mt-1() {{ $t('Accept and continue') }}

</template>

<script>

export default {
  name: 'TermsOfUse',
  components: {
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton')
  },
  mounted () {
  },
  data () {
    return {
      closePopup: false,
      url: "http://google.com"
    }
  },
  methods: {
    getTermsUrl () {
      return 'en.html'
    }
  }
}
</script>

<style lang="scss">
.terms-of-use {
  &__view {
    height: 400px;
  }
}
</style>