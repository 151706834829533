<template lang="pug">
form.confirm-code
  LPopup(
    @close="closePopup"
  )
    h3.mt-3.mb-3 {{ $t('signup code')}}
    LTimer.confirm-code__timer.mb-1(
      v-if="showTimer"
      :count="6000"
      :timestamp="Date.now()"
      @end="showTimer = false"
      :class="{'confirm-code__timer_alert': alert}"
    )
    .confirm-code__resend(@click="resendCode()") {{ $t('resend code') }}
    LInput.mt-2(
      :placeholder="`* ${$t('email')}`"
      v-model="email"
      v-if="email === null"
      :error="emailErrorsPull.length > 0"
      autocomplite="off"
    )
    p.confirm-code__error(v-if="!!getErrorMessage(emailErrorsPull) && email !== null") {{ getErrorMessage(emailErrorsPull) }}
    LInput.mt-1(
      :placeholder="`* ${$t('code')}`"
      v-focus
      v-model="code"
      :error="codeErrorsPull.length > 0"
      @focus="clearErrors('code')"
      @blur="validateCode()"
      autocomplite="off"
    )
    p.confirm-code__error(v-if="!!getErrorMessage(codeErrorsPull)") {{ getErrorMessage(codeErrorsPull) }}
    LButton.mt-1(@click="sendConfirm()" :preloader="preloader") {{ $t('next') }}

</template>

<script>
import { mapActions } from 'vuex'
import validationMixin from '@/mixins/validation.js'
import Cookie from 'js-cookie'

export default {
  name: 'ConfirmCode',
  components: {
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LInput: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInput'),
    LTimer: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTimer')
  },
  mixins: [validationMixin],
  mounted () {
    if (this.$route.params.email) {
      this.email = this.$route.params.email
    }
  },
  data () {
    return {
      email: null,
      code: null,
      showTimer: true,
      preloader: false,
      codeErrorsPull: [],
      emailErrorsPull: [],
      alert: false
    }
  },
  methods: {
    ...mapActions('user', ['confirm', 'resendConfirmCode']),
    closePopup () {
      // save data to localstorage
      this.$router.push('/')
    },
    getErrorMessage (pull) {
      if (typeof pull[0] !== 'undefined') {
        return pull[0].message
      }
      return false
    },
    clearErrors (field) {
      if (field === 'code') this.codeErrorsPull = []
      if (field === 'email') this.emailErrorsPull = []
    },
    alertHandler () {
      this.alert = true
      setTimeout(() => {
        this.alert = false
      }, 3000)
    },
    resendCode () {
      if (this.showTimer) {
        this.alertHandler()
        return
      }
      this.resendConfirmCode({
        email: this.email
      })
      .then((response) => { this.showTimer = true })
    },
    validateEmail () {
      let valid = true
      // required
      if (this.isEmpty(this.email)) {
        this.emailErrorsPull.push({ error: 'required', message: 'заполните поле email'})
        valid = false
      } else {
        let errorIndex = this.emailErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.emailErrorsPull.splice(errorIndex, 1)
      }
      // is email
      if (valid && !this.isEmail(this.email)) {
        this.emailErrorsPull.push({ error: 'email', message: 'введите корректный емайэ'})
        valid = false
      } else {
        let errorIndex = this.emailErrorsPull.findIndex((element) => element.error === "email")
        if (errorIndex >= 0) this.emailErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateCode () {
      let valid = true
      if (this.isEmpty(this.code)) {
        this.codeErrorsPull.push({ error: 'required', message: 'must be filled'})
        valid = false
        return false
      }
      if (this.minLength(this.code, 4)) {
        this.codeErrorsPull.push({ error: 'minlength', message: 'must be minimum 4 symbols'})
        valid = false
      }
      return valid
    },
    validateAll () {
      return this.validateEmail() && this.validateCode()
    },
    sendConfirm () {
      if (!this.validateAll()) return
      this.preloader = true
      this.confirm({
        email: this.email,
        code_confirm: this.code
      })
      .then(resolve => {
        // if ok
        this.$router.push('/signin')
      })
      .catch(e => {

      })
      .finally(() => {
        this.preloader = false
      })
    }
  }
}
</script>

<style lang="scss">
.confirm-code {
  &__text {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.03em;
  }
  &__timer {
    font-size: 24px;
    font-weight: 700;
    &_alert {
      animation: alert 1s infinite ease-in-out;
    }
  }
  &__resend {
    text-decoration: underline;
    text-transform: uppercase;
    cursor: pointer;
    display: inline;
    &:hover {
      color: #1E4BD2;
    }
  }
  &__error {
    color: #E12323;
    margin-top: 2px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }
}
@keyframes alert {
  50% {
    color: #E12323;
  }
}
</style>