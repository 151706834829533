<template lang="pug">
form.recovery
  LPopup(
  )
    h3.mt-3.mb-5 {{ $t('forgot password') }}
    h3 {{ $t('recovery via') }}
    p.mt-3 {{ $t('send code info') }}
    LInput.recovery__text.mt-3(
      v-model="email"
      :placeholder="`* ${$t('email')}`"
      :error="emailErrorsPull.length > 0"
      @blur="validateEmail()"
      @focus="clearErrors('email')"
      autocomplite="off"
    )
    p.recovery__error(v-if="emailErrorsPull.length > 0") {{ getErrorMessage(emailErrorsPull) }}
    LButton.mt-2(@click="send" :preloader="preloader") {{ $t('next') }}

</template>

<script>
import { mapActions } from 'vuex'
import validationMixin from '@/mixins/validation.js'

export default {
  name: 'Recovery',
  components: {
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LInput: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInput'),
    LTimer: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTimer')
  },
  mixins: [validationMixin],
  data () {
    return {
      email: null,
      preloader: false,
      emailErrorsPull: []
    }
  },
  methods: {
    ...mapActions('user', ['recovery']),
    closePopup () {
      // save data to localstorage
      this.$router.push('/')
    },
    getErrorMessage (pull) {
      if (typeof pull[0] !== 'undefined') {
        return pull[0].message
      }
      return false
    },
    clearErrors (field) {
      if (field === 'email') this.emailErrorsPull = []
    },
    validateEmail () {
      let valid = true
      // required
      if (this.isEmpty(this.email)) {
        this.emailErrorsPull.push({ error: 'required', message: 'заполните поле email'})
        valid = false
      } else {
        let errorIndex = this.emailErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.emailErrorsPull.splice(errorIndex, 1)
      }
      // is email
      if (valid && !this.isEmail(this.email)) {
        this.emailErrorsPull.push({ error: 'email', message: 'введите корректный емайэ'})
        valid = false
      } else {
        let errorIndex = this.emailErrorsPull.findIndex((element) => element.error === "email")
        if (errorIndex >= 0) this.emailErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    send () {
      if (!this.validateEmail()) return
      this.preloader = true
      this.recovery(this.email)
      .then((response) => {
        if (response.data.result?.message === 'The selected email is invalid.') {
          this.emailErrorsPull.push({ error: 'nothave', message: 'Указаный email не зарегистрирован'})
        } else {
          this.$router.push(`/recovery/code/${this.email}`)
        }
      })
      .catch(e => {

      })
      .finally(() => {
        this.preloader = false
      })
    }
  }
}
</script>

<style lang="scss">
.recovery {
  &__text {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.03em;
  }
  &__error {
    color: #E12323;
    margin-top: 2px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>