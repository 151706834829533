<template lang="pug">
form.signup
  LPopup(
    @close="closePopup"
  )
    .container(v-show="!showTermsOfUse")
      h3.mt-3.mb-5 {{ $t('signup') }}
      //- FIELDS
      LDropdown.signup__select-role(:placeholder="`* ${$t('User role')}`" :error="roleErrorsPull.length > 0")
        span.signup__userrole(slot="value" v-if="!!userRole") {{ userRole }}
        template(slot="items")
          .signup__userrole-item(
            v-for="item in ['user', 'advertiser']"
            @click="userRole = item"
          ) {{ $t(`${item}`) }}

      p.signup__error(v-if="!!getErrorMessage(roleErrorsPull)") {{ getErrorMessage(roleErrorsPull) }}
      LInput.mt-1(
        :placeholder="`* ${$t('login')}`"
        v-model="userName"
        @blur="validateUsername()"
        @focus="clearErrors('username')"
        :error="usernameErrorsPull.length > 0"
        autocomplite="off"
      )
      p.signup__error(v-if="!!getErrorMessage(usernameErrorsPull)") {{ getErrorMessage(usernameErrorsPull) }}
      LInput.mt-1(
        :placeholder="`* ${$t('email')}`"
        v-model="userEmail"
        @blur="validateEmail()"
        @focus="clearErrors('email')"
        :error="emailErrorsPull.length > 0"
        autocomplite="off"
      )
      p.signup__error(v-if="!!getErrorMessage(emailErrorsPull)") {{ getErrorMessage(emailErrorsPull) }}
      LInput.mt-1(
        type="password"
        :placeholder="`* ${$t('password')}`"
        v-model="userPassword"
        @blur="validatePassword()"
        @focus="clearErrors('password')"
        :error="passwordErrorsPull.length > 0"
        autocomplite="off"
      )
      p.signup__error(v-if="!!getErrorMessage(passwordErrorsPull)") {{ getErrorMessage(passwordErrorsPull) }}
      LCheckbox.mt-1(v-model="acceptTermsOfUse" :error="termsofuseErrorsPull.length > 0")
        span(v-html="termsOfUseLabel()")

      p.signup__error(v-if="!!getErrorMessage(termsofuseErrorsPull)") {{ getErrorMessage(termsofuseErrorsPull) }}
      LButton.mt-2(@click="sendSignup()" :preloader="preloader") {{ $t('next') }}

    template(v-if="showTermsOfUse")
      h3.mt-3.mb-2 {{ $t('terms of use') }}
      iframe.signup__view(:src="termsUrl")
      LButton.mt-2(theme="blue" @click="$router.go(-1)") {{ $t('back') }}
      LButton.mt-2(@click="accept()") {{ $t('accept and continue') }}

</template>

<script>
// fix body scroll
import { mapActions, mapGetters } from 'vuex'
import validationMixin from '@/mixins/validation.js'

export default {
  name: 'Signup',
  components: {
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LInput: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInput'),
    LDropdown: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LDropdown'),
    LCheckbox: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCheckbox')
  },
  mixins: [validationMixin],
  created () {
    this.refer = this.$route.query.refer
    console.log(this.$route)
  },
  mounted () {
    this.showPopup = true
    if (this.$route.hash === '#termsofuse') this.showTermsOfUse = true
  },
  data () {
    return {
      userName: null,
      userEmail: null,
      userPassword: null,
      userRole: null,
      acceptTermsOfUse: true,
      refer: null,

      preloader: false,
      showTermsOfUse: false,

      // validation fields

      usernameErrorsPull: [],
      emailErrorsPull: [],
      passwordErrorsPull: [],
      roleErrorsPull: [],
      termsofuseErrorsPull: []
    }
  },
  computed: {
    ...mapGetters('user', ['HAS_AUTH', 'USER_DATA']),
    termsUrl () {
      return "://likeeng.uk/termsofuse/en.html"
    }
  },
  methods: {
    ...mapActions('user', ['signup']),
    termsOfUseLabel () {
      return this.$t('I have read terms of use and agree', { link: `<a href="#termsofuse">${this.$t('terms of use')}</a>` })
    },
    accept () {
      this.acceptTermsOfUse = true;
      this.showTermsOfUse = false;
      this.$router.replace('/signup')
    },
    closePopup () {
      this.$router.push('/')
    },
    // VALIDATION LOGIC
    getErrorMessage (pull) {
      if (typeof pull[0] !== 'undefined') {
        return pull[0].message
      }
      return false
    },
    clearErrors (field) {
      if (field === 'username') this.usernameErrorsPull = []
      if (field === 'email') this.emailErrorsPull = []
      if (field === 'password') this.passwordErrorsPull = []
      if (field === 'termsofuse') this.passwordErrorsPull = []
    },
    validateUsername () {
      let valid = true
      // required
      if (this.isEmpty(this.userName)) {
        this.usernameErrorsPull.push({ error: 'required', message: 'заполните поле логин'})
        valid = false
      } else {
        let errorIndex = this.usernameErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.usernameErrorsPull.splice(errorIndex, 1)
      }
      // min length
      if (valid && this.minLength(this.userName, 5)) {
        this.usernameErrorsPull.push({ error: 'minlength', message: 'минимум 5 символов'})
        valid = false
      } else {
        let errorIndex = this.usernameErrorsPull.findIndex((element) => element.error === "minlength")
        if (errorIndex >= 0) this.usernameErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateEmail () {
      let valid = true
      // required
      if (this.isEmpty(this.userEmail)) {
        this.emailErrorsPull.push({ error: 'required', message: 'заполните поле email'})
        valid = false
      } else {
        let errorIndex = this.emailErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.emailErrorsPull.splice(errorIndex, 1)
      }
      // is email
      if (valid && !this.isEmail(this.userEmail)) {
        this.emailErrorsPull.push({ error: 'email', message: 'введите корректный емайэ'})
        valid = false
      } else {
        let errorIndex = this.emailErrorsPull.findIndex((element) => element.error === "email")
        if (errorIndex >= 0) this.emailErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validatePassword () { // TODO ADD PASSWORD FORMAT TEST
      let valid = true
      // required
      if (this.isEmpty(this.userPassword)) {
        this.passwordErrorsPull.push({ error: 'required', message: 'заполните поле пароль'})
        valid = false
      } else {
        let errorIndex = this.passwordErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.passwordErrorsPull.splice(errorIndex, 1)
      }
      // min length
      if (valid && this.minLength(this.userPassword, 5)) {
        this.passwordErrorsPull.push({ error: 'minlength', message: 'минимум 5 символов'})
        valid = false
      } else {
        let errorIndex = this.passwordErrorsPull.findIndex((element) => element.error === "minlength")
        if (errorIndex >= 0) this.passwordErrorsPull.splice(errorIndex, 1)
      }
      if (valid && !this.securePassword(this.userPassword)) {
        this.passwordErrorsPull.push({ error: 'secure', message: 'пароль должен сожержать минимум 1 цифру, минимум 1 букву, и минимум 1 заглавную букву'})
        valid = false
      } else {
        let errorIndex = this.passwordErrorsPull.findIndex((element) => element.error === "secure")
        if (errorIndex >= 0) this.passwordErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateTermsOfUse () {
      // required checked
      if (!this.acceptTermsOfUse) {
        this.termsofuseErrorsPull.push({ error: 'required', message: 'необходимо принять'})
        return false
      }
      return true
    },
    validateRole () {
      if (this.isEmpty(this.userRole)) {
        this.roleErrorsPull.push({ error: 'required', message: 'необходимо выбрать'})
        return false
      }
      return true
    },
    validateAll () {
      if (
        this.validateRole() &&
        this.validateUsername() &&
        this.validateEmail() &&
        this.validatePassword() &&
        this.validateTermsOfUse()
      ) return true
      return false
    },
    // END VALIDATION LOGIC
    sendSignup () {
      if (!this.validateAll()) return
      this.preloader = true
      let obj = {
        username: this.userName,
        email: this.userEmail,
        password: this.userPassword,
        type: this.userRole
      }
      console.log('req', this.refer)
      if (this.refer !== null) obj['refer'] = this.refer
      this.signup(obj)
      .then((response) => {
        if (response.status === 200) { // its ok
          this.$router.push(`/confirm/${this.userEmail}`)
        }

        if (response.status === 422) { // validation error
          if (response.data.result.message === 'The email has already been taken.' || 
               response.data.result.message === 'Этот email уже занят. Придумайте другой.' ) { // bad
            this.emailErrorsPull.push({ error: 'taken', message: 'Этот адрес электронной почты уже занят'})
          }
          if (response.data.result.message === 'The username has already been taken.' ||
                response.data.result.message === 'Этот username уже занят. Придумайте другой.') { // bad
            this.usernameErrorsPull.push({ error: 'taken', message: 'Это имя пользователя уже занато'})
          }
        }

      })
      .catch ((e) => {
      })
      .finally(() => {
        this.preloader = false
      })
    }
  },
  watch: {
    $route(to, from) {
      if (to.hash === '#termsofuse') {
        this.showTermsOfUse = true
      } else {
        this.showTermsOfUse = false
      }
    },
    acceptTermsOfUse (newVal) {
      let errorIndex = this.termsofuseErrorsPull.findIndex((element) => element.error === "required")
      if (errorIndex >= 0) this.termsofuseErrorsPull.splice(errorIndex, 1)
    },
    userRole (newVal) {
      let errorIndex = this.roleErrorsPull.findIndex((element) => element.error === "required")
      if (errorIndex >= 0) this.roleErrorsPull.splice(errorIndex, 1)
    }
  }
}
</script>

<style lang="scss">
.signup {
  & .lpopup {
    &__body {
      display: flex;
      flex-direction: column;
    }
  }

  &__userrole {
    text-transform: capitalize;
  }

  &__userrole-item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    text-transform: capitalize;
    &:hover {
      background-color: #EAEAEA;
      cursor: pointer;
    }
  }
  &__error {
    color: #E12323;
    margin-top: 2px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }
  &__select-role {
    & .ldropdown__selected {
      padding: 0 15px;
    }
  }
  &__view {
    height: 300px;
    width: 100%;
  }
}
</style>