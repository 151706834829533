<template lang="pug">
.signin
  LPopup(
    @close="closePopup"
  )
    h3.mt-2.mb-5 {{ $t('signin') }}
    p.signin__error.mb-1(v-if="userFail") {{ $t('Не правильный пользователь или пароль') }}
    LInput(
      :placeholder="`* ${$t('login or email')}`"
      v-model="login"
      v-focus
      :error="loginErrorsPull.length > 0"
      @blur="validateLogin"
      @focus="clearErrors('login')"
    )
    p.signin__error(v-if="loginErrorsPull.length > 0") {{ getErrorMessage(loginErrorsPull) }}
    LInput.mt-1(
      type="password"
      :placeholder="`* ${$t('password')}`"
      v-model="password"
      :error="passwordErrorsPull.length > 0"
      @blur="validatePassword()"
      @focus="clearErrors('password')"
    )
    p.signin__error(v-if="passwordErrorsPull.length > 0") {{ getErrorMessage(passwordErrorsPull) }}
    LButton.mt-2.mb-4.ml-a.signin__recovery(
      theme="transparent"
      size="small"
      @click="toRecovery"
    ) {{ $t('forgot password') }}?
    LButton(@click="send" :preloader="preloader") {{ $t('signin') }}

</template>

<script>
// TODO separate mixins
// fix body scroll
import { mapActions } from 'vuex'
import validationMixin from '@/mixins/validation.js'

export default {
  name: 'Signin',
  components: {
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LInput: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInput')
  },
  mixins: [validationMixin],
  data () {
    return {
      showPopup: true,
      login: null,
      password: null,
      preloader: false,
      userFail: false,

      loginErrorsPull: [],
      passwordErrorsPull: []
    }
  },
  methods: {
    ...mapActions('user', ['signin', 'settings']),
    closePopup () {
      this.$router.push('/')
    },
    getErrorMessage (pull) { // TODO REFACTORING
      if (typeof pull[0] !== 'undefined') {
        return pull[0].message
      }
      return false
    },
    clearErrors (field) {
      this.userFail = false
      if (field === 'login') this.loginErrorsPull = []
      if (field === 'password') this.passwordErrorsPull = []
    },
    validateLogin () {
      let valid = true
      // required
      if (this.isEmpty(this.login)) {
        this.loginErrorsPull.push({ error: 'required', message: 'заполните поле логин'})
        valid = false
      } else {
        let errorIndex = this.loginErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.loginErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validatePassword () {
      let valid = true
      // required
      if (this.isEmpty(this.password)) {
        this.passwordErrorsPull.push({ error: 'required', message: 'заполните поле пароль'})
        valid = false
      } else {
        let errorIndex = this.passwordErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.passwordErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateAll () {
      return this.validateLogin() && this.validatePassword()
    },
    toRecovery () {
      this.$router.push('/recovery')
    },
    send () {
      if (!this.validateAll()) return
      this.preloader = true
      this.signin({
        login_or_email: this.login,
        password: this.password
      })
      .then((response) => {
        if (!!response.data.result && response.data.result.message === 'User not found.') { this.userFail = true }
        if (response.status === 200) {
          this.settings()
          .then((res) => {
            if (res.data.data.name === null) {
              this.$router.push('/signup/additional')
            } else {
              window.location.href = '/app'
            }
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        this.preloader = false
      })
    }
  }
}
</script>

<style lang="scss">
.signin {
  &__recovery {
    display: inline-flex;
    padding-right: 0px;
    font-weight: 700;
    font-size: 16px;
  }
  & .lpopup {
    &__body {
      display: flex;
      flex-direction: column;
    }
  }
  &__error {
    color: #E12323;
    margin-top: 2px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }
}

</style>