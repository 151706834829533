<template lang="pug">
form.recovery-code
  LPopup(
  )
    template(v-if="step === 1")
      h3.mt-3.mb-3 {{ $t('forgot password') }}
      LTimer.recovery-code__timer.mb-2(
        :count="6000"
        :timestamp="Date.now()"
      )
      .recovery-code__resend.mb-2 {{ $t('resend code') }}
      LInput.recovery-code__text(
        :placeholder="`* ${$t('email')}`"
        v-if="email === null"
        v-model="email"
        :error="emailErrorsPull.length > 0"
        @blur="validateEmail()"
        @focus="clearErrors('email')"
        autocomplite="off"
      )
      p.recovery-code__error(v-if="emailErrorsPull.length > 0 && email !== null") {{ getErrorMessage(emailErrorsPull) }}
      LInput.recovery-code__text.mt-1(
        v-focus
        v-model="code"
        :placeholder="`* ${$t('enter code')}`"
        :error="codeErrorsPull.length > 0"
        @blur="validateCode()"
        @focus="clearErrors('code')"
        autocomplite="off"
      )
      p.recovery-code__error(v-if="codeErrorsPull.length > 0") {{ getErrorMessage(codeErrorsPull) }}
      LInput.recovery-code__text.mt-1(
        :placeholder="`* ${$t('password')}`"
        type="password"
        v-model="password"
        :error="passwordErrorsPull.length > 0"
        @blur="validatePassword()"
        @focus="clearErrors('password')"
        autocomplite="off"
      )
      p.recovery-code__error(v-if="passwordErrorsPull.length > 0") {{ getErrorMessage(passwordErrorsPull) }}
      LInput.recovery-code__text.mt-1(
        :placeholder="`* ${$t('repeat password')}`"
        type="password"
        v-model="rePassword"
        :error="rePasswordErrorsPull.length > 0"
        @blur="validateRePassword()"
        @focus="clearErrors('rePassword')"
        autocomplite="off"
      )
      p.recovery-code__error(v-if="rePasswordErrorsPull.length > 0") {{ getErrorMessage(rePasswordErrorsPull) }}
      LButton.mt-2(
        @click="sendPassword()"
      ) {{ $t('next') }}

    template(v-if="step === 2")
      h2.recovery-code__title {{ $t('password changed') }}
      LButton.mt-2(@click="$router.push('/signin')") {{ $t('next') }}

</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex'
import validationMixin from '@/mixins/validation.js'

export default {
  name: 'RecoveryCode',
  components: {
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LInput: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInput'),
    LTimer: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTimer')
  },
  mounted () {
    if (this.$route.params.email) this.email = this.$route.params.email
  },
  mixins: [validationMixin],
  data () {
    return {
      email: null,
      step: 1,
      code: null,
      password: null,
      rePassword: null,
      emailErrorsPull: [],
      passwordErrorsPull: [],
      rePasswordErrorsPull: [],
      codeErrorsPull: []
    }
  },
  methods: {
    ...mapActions('user', ['recoverySendCode']),
    closePopup () {
      // save data to localstorage
      this.$router.push('/')
    },
    sendNewPassword () {

    },
    getErrorMessage (pull) { 
      if (typeof pull[0] !== 'undefined') {
        return pull[0].message
      }
      return false
    },
    clearErrors (field) {
      if (field === 'code') this.codeErrorsPull = []
      if (field === 'email') this.emailErrorsPull = []
      if (field === 'password') this.passwordErrorsPull = []
      if (field === 'rePassword') this.rePasswordErrorsPull = []
    },
    validateEmail () {
      let valid = true
      // required
      if (this.isEmpty(this.email)) {
        this.emailErrorsPull.push({ error: 'required', message: 'заполните поле email'})
        valid = false
      } else {
        let errorIndex = this.emailErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.emailErrorsPull.splice(errorIndex, 1)
      }
      // is email
      if (valid && !this.isEmail(this.email)) {
        this.emailErrorsPull.push({ error: 'email', message: 'введите корректный емайэ'})
        valid = false
      } else {
        let errorIndex = this.emailErrorsPull.findIndex((element) => element.error === "email")
        if (errorIndex >= 0) this.emailErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateCode () {
      let valid = true
      // required
      if (this.isEmpty(this.code)) {
        this.codeErrorsPull.push({ error: 'required', message: 'заполните поле code'})
        valid = false
      } else {
        let errorIndex = this.codeErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.codeErrorsPull.splice(errorIndex, 1)
      }
      // is email
      if (valid && this.minLength(this.code, 4)) {
        this.codeErrorsPull.push({ error: 'minlength', message: 'миниимум 4 сомвола'})
        valid = false
      } else {
        let errorIndex = this.codeErrorsPull.findIndex((element) => element.error === "minlength")
        if (errorIndex >= 0) this.codeErrorsPull.splice(errorIndex, 1)
      }
      return valid

    },
    validatePassword () {
      let valid = true
      // required
      if (this.isEmpty(this.password)) {
        this.passwordErrorsPull.push({ error: 'required', message: 'заполните поле пароль'})
        valid = false
      } else {
        let errorIndex = this.passwordErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.passwordErrorsPull.splice(errorIndex, 1)
      }
      // min length
      if (valid && this.minLength(this.password, 5)) {
        this.passwordErrorsPull.push({ error: 'minlength', message: 'минимум 5 символов'})
        valid = false
      } else {
        let errorIndex = this.passwordErrorsPull.findIndex((element) => element.error === "minlength")
        if (errorIndex >= 0) this.passwordErrorsPull.splice(errorIndex, 1)
      }
      if (valid && !this.securePassword(this.password)) {
        this.passwordErrorsPull.push({ error: 'secure', message: 'пароль должен сожержать минимум 1 цифру, минимум 1 букву, и минимум 1 заглавную букву'})
        valid = false
      } else {
        let errorIndex = this.passwordErrorsPull.findIndex((element) => element.error === "secure")
        if (errorIndex >= 0) this.passwordErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateRePassword () {
      let valid = true
      // required
      if (this.isEmpty(this.rePassword)) {
        this.rePasswordErrorsPull.push({ error: 'required', message: 'заполните поле пароль'})
        valid = false
      } else {
        let errorIndex = this.rePasswordErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.rePasswordErrorsPull.splice(errorIndex, 1)
      }
      // min length
      if (valid && this.minLength(this.rePassword, 5)) {
        this.rePasswordErrorsPull.push({ error: 'minlength', message: 'минимум 5 символов'})
        valid = false
      } else {
        let errorIndex = this.rePasswordErrorsPull.findIndex((element) => element.error === "minlength")
        if (errorIndex >= 0) this.rePasswordErrorsPull.splice(errorIndex, 1)
      }
      if (valid && !this.securePassword(this.rePassword)) {
        this.rePasswordErrorsPull.push({ error: 'secure', message: 'пароль должен сожержать минимум 1 цифру, минимум 1 букву, и минимум 1 заглавную букву'})
        valid = false
      } else {
        let errorIndex = this.rePasswordErrorsPull.findIndex((element) => element.error === "secure")
        if (errorIndex >= 0) this.rePasswordErrorsPull.splice(errorIndex, 1)
      }
      if (valid && this.password !== this.rePassword) {
        this.rePasswordErrorsPull.push({ error: 'coincidence', message: 'пароли должны совпадать'})
        valid = false
      } else {
        let errorIndex = this.rePasswordErrorsPull.findIndex((element) => element.error === "coincidence")
        if (errorIndex >= 0) this.rePasswordErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateAll () {
      return (this.validateEmail() &&
        this.validateCode() &&
        this.validatePassword() &&
        this.validateRePassword()
      )
    },
    sendPassword () {
      if (!this.validateAll()) return
      this.preloader = true
      this.recoverySendCode({
        email: this.email,
        password: this.password,
        code_confirm: this.code
      })
      .then((response) => {
        if (response.data.result?.status) {
          this.step = 2
        } else {
          this.codeErrorsPull.push({ error: 'code_invalid', message: 'Неправильный код'})
        }
        
      })
      .catch(e => {})
      .finally(() => {
        this.preloader = false
      })
    },
    repeatCode () {
      if (this.showTimer) return
      this.resendRecoveryCode({
        email: this.email
      })
      .then((response) => { this.showTimer = true })
    }
  }
}
</script>

<style lang="scss">
.recovery-code {
  &__text {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.03em;
  }
  &__timer {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: .03em;
  }
  &__resend {
    text-transform: uppercase;
    text-decoration: underline;
  }
  &__error {
    color: #E12323;
    margin-top: 2px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>